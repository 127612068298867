import React from 'react';
import { Link } from 'react-router-dom';
import { BiLogoFacebook, BiLogoInstagram, BiLogoTwitter } from 'react-icons/bi';
import { MdOutlineMailOutline } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io5";
import './footer.css'

function Footer() {

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      {/* <!-- Footer Start --> */}
      <div
        className="container-fluid  mt-5 py-4 px-sm-3 px-md-5"
      >
        <div class="row pt-5">
          <div className="col-md-6 col-lg-6 ">
            <h2 className='footer_contact'>Contact&nbsp;<span style={{ color: '#F7631B' }}>Us</span> </h2>
            <p className='footer_sub_text'><a href="mailto:support@quickassignment.co.uk" style={{ textDecoration: 'none', color: '#000' }}><MdOutlineMailOutline className='mr-3' style={{ margin: '-5px' }} /><span>support@quickassignment.co.uk</span></a></p>
            <div className='row'>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 mb-5">
            <h3 className=" mb-4">Know us</h3>
            <div className="d-flex flex-column justify-content-start">
              <Link className="link_text mb-2" to="faq" onClick={goToTop}
              >FAQ</Link>
              {/* <Link className="link_text mb-2" to="Joinourteam" onClick={goToTop}
              >Join Our Team</Link>
              <Link className="link_text mb-2" to="how_it_works" onClick={goToTop}
              >How it works</Link> */}
              <Link className="link_text mb-2" to="about" onClick={goToTop}
              >About Us</Link>
              <Link className="link_text mb-2" style={{}} to="contact" onClick={goToTop}
              >Contact Us</Link>
            </div>

            {/* <div className="d-flex justify-content-center mt-4">
              <a
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0 link_text"
                // style="width: 38px; height: 38px"
                style={{ width: '38px', height: '38px' }}
                href="https://twitter.com/asahelps" target='blank'
              ><i> <BiLogoTwitter style={{ fontSize: '1.3em' }} /></i></a>
              <a
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0 link_text"
                style={{ width: '38px', height: '38px' }}
                href="https://www.facebook.com/asahelps" target='blank'
              ><i> <BiLogoFacebook style={{ fontSize: '1.3em' }} /> </i></a>
              <a
                className="btn btn-outline-primary rounded-circle text-center mr-2 px-0 link_text"
                style={{ width: '38px', height: '38px' }}
                href="https://www.instagram.com/asahelps/" target='blank'
              ><i> <BiLogoInstagram style={{ fontSize: '1.3em' }} /> </i></a>
            </div> */}
          </div>

          {/* <div className="col-lg-2 col-md-6 mb-5">
            <h3 className=" mb-4">Features</h3>
            <div className="d-flex flex-column justify-content-start">
              <Link className="link_text mb-2" to="blogs" onClick={goToTop}
              >Blog</Link>
              <Link className="link_text mb-2" to="terms_conditions" onClick={goToTop}
              >Terms & Conditions</Link>
              <Link className="link_text mb-2" to="privacy_policy" onClick={goToTop}
              >Privacy & Policy</Link>
              <Link className="link_text mb-2" to="refund_cancellation_policy" onClick={goToTop}
              >Refund & Cancellation Policy</Link>
            </div>
          </div> */}

          {/* <div className="col-lg-2 col-md-6 mb-5">
            <h3 className=" mb-4">Best in countries</h3>
            <div className="d-flex flex-column justify-content-start">
              <Link className="link_text mb-2" to="australia" onClick={goToTop}
              >Australia</Link>
              <Link className="link_text mb-2" to="newzealand" onClick={goToTop}
              >New Zealand</Link>
              <Link className="link_text mb-2" to="canada" onClick={goToTop}
              >Canada</Link>

            </div>
          </div> */}
        </div>
        <div
          className="container-fluid pt-4"
          // style="border-top: 1px solid rgba(23, 162, 184, 0.2) ;"
          style={{ borderTop: "1px solid rgba(23, 162, 184, 0.2)" }}
        >
          <p className="m-0 text-center ">
            &copy;&nbsp;
            <Link className="link_text font-weight-bold " onClick={goToTop} to={"/"}>2024 QuickAssignment</Link>.&nbsp;
            All Rights Reserved.&nbsp;
          </p>
        </div>
      </div>

      {/* <!-- Footer End --> */}
    </React.Fragment>
  )
}

export default Footer
